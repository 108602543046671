@import '../../../../assets/scss/colors.scss';
@import '../../../../assets/scss/break-points.scss';

.company-profile-tab {
  .watchlist-card {
    width: 100%;

    .watchlist-data {
      width: 100%;

      @include bp(lg-max) {
        padding: 15px;
      }

      @include bp(sm-max) {
        flex-wrap: wrap;
      }

      .left {
        svg {
          margin-right: 16px;
          width: 16px;
          min-width: 16px;
        }

        @include bp(sm-max) {
          max-width: 100%;
          flex: 0 0 100%;
        }
      }

      .right {
        padding-left: 20px;

        @include bp(sm-max) {
          max-width: 100%;
          flex: 0 0 100%;
          padding: 20px 0 0;
        }

        .btn {
          min-width: 190px;
        }
      }
    }
  }
}

.protech-detail-inner {
  .tab-panels {
    .tabpanel-wrapper {
      h2 {
        .number-txt {
          color: $secondary;
        }

        &.left-bordered-text {
          margin: 20px 0 4px;
          font-size: 24px;
          border-left: 4px solid;
          padding: 4px 16px;

          @include bp(sm-max) {
            font-size: 20px;
          }

          &.orange-border {
            border-color: $secondary;
          }

          &.blue-border {
            border-color: $blue;
          }
        }
      }

      .blue-theme {
        h2 {
          .number-txt {
            color: $blue;
          }
        }
      }
    }
  }
}

.modal-design {
  display: flex;
  flex-wrap: wrap;

  @include bp(xsm-max) {
    &+.modal-design {
      margin-top: 20px;
    }
  }

  .col-6 {
    flex: 0 0 50%;
    max-width: 50%;

    @include bp(xsm-max) {
      max-width: 100%;
      flex: 0 0 100%;

      &+.col-6 {
        margin-top: 25px;
      }
    }

    span {
      font-weight: 600;
      font-size: 12px;
      line-height: 1.5;
      color: $primary;
      opacity: 0.6;
      display: block;
      padding-bottom: 15px;
      text-transform: uppercase;
      font-family: 'Poppins';
    }

    .detail-sectors {
      @include bp(xsm-max) {
        padding-bottom: 0;
      }

      img {
        width: 40px;
      }
    }

    .growth-info {
      .growth-item {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 0 12px 16px 30px;

        @include bp(sm-max) {
          padding-bottom: 0;
        }

        @include bp(xsm-max) {
          padding-bottom: 0;
          margin-bottom: 12px;
          padding-left: 15px;
        }

        img {
          width: 40px;
        }

        h3 {
          line-height: 1;
        }
      }
    }
  }
}

.key-metrics {

  &.market-growth {
    border: none;

    &.has-numbers {
      .growth-info {
        .growth-item {
          &::after {
            @include bp(xsm-max) {
              left: 0;
              top: 0px;
            }
          }
        }
      }
    }

    .growth-info {
      counter-reset: my-sec-counter;

      .growth-item {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 0 12px 16px 30px;

        @include bp(xsm-max) {
          padding-bottom: 0;
          padding-left: 15px;
          margin-bottom: 20px;
        }

        &:after {
          counter-increment: my-sec-counter;
          content: counter(my-sec-counter) ". ";
          background-image: none;
          width: auto;
          height: auto;
          color: $secondary;
          font-weight: 600;
          font-size: 14px;
          line-height: 1;
          top: 0px;

          @include bp(xsm-max) {
            left: 0;
            top: 0px;
          }
        }
      }
    }
  }
}

.metrics-wrapper {
  &.has-three-col {
    span {
      font-weight: 400;
      font-size: 12px;
      line-height: 1.5;
      display: block;
      color: #70778E;
      padding-bottom: 4px;
      opacity: 1;
      text-transform: none
    }

    .metrics-item {
      max-width: 30%;
      flex: 0 0 30%;
      word-break: break-all;
      // align-self: flex-start;

      @include bp(xlg-max) {
        max-width: 50%;
        flex: 0 0 50%;
      }

      @include bp(xsm-max) {
        max-width: 100%;
        flex: 0 0 100%;
      }
    }
  }

  &.has-five-col {
    .metrics-item {
      padding: 0 20px;
      max-width: 20%;
      flex: 0 0 20%;
      margin-bottom: 20px;
      word-break: break-all;

      // align-self: flex-start;
      @include bp(lg-max) {
        max-width: 50%;
        flex: 0 0 50%;
      }

      @include bp(xsm-max) {
        max-width: 100%;
        flex: 0 0 100%;
      }
    }
  }
}

.key-customer-logo {
  li {
    img {
      margin: 0 !important;
      border-radius: 54px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
.st-20,
.st-330 {
  display: none;
}
@media print {
  body{
    -webkit-print-color-adjust:exact !important;
    print-color-adjust:exact !important;
  }
  @page {
    size: 210mm 297mm;
    box-shadow: 0;
    margin: 15px 0;
  }
  .market-growth,
  .protech-detail-inner .raise-detail-wrapper {
    break-inside: avoid;
  }
  .protech-detail-inner .tab-panels .tabpanel-wrapper h2.left-bordered-text {
    page-break-after: avoid !important;
  }
  .market-overview .item-market,
  .market-growth .growth-info .growth-item {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
  .market-overview .item-market.col-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .metrics-wrapper .metrics-item {
    max-width: 25%;
    flex: 0 0 25%;
  }
  .metrics-wrapper.has-five-col .metrics-item {
    max-width: 20%;
    flex: 0 0 20%;
    padding: 0 10px;
  }
  .proptech-detail-wrapper .navigation-top {
    display: none;
  }
  .proptech-detail-wrapper .top-menu {
    padding-top: 15px !important;
  }
  .st-330,
  .st-20 {
    display: block;
  }
  .st-20 {
    margin-top: 25px;
  }
  .st-330 {
    margin-top: 330px;
  }
  .st-155 {
    margin-top: 155px !important;
  }
}