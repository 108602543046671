@import '../../../../assets/scss/break-points.scss';

.admin-proptech-tab {
  &.investor-proptech-tab {
    .right-block {
      .right-top-heading {

        @include bp(xsm-max) {
          align-items: flex-start !important;
          justify-content: center !important;
        }
      }
    }

    .investor-card-wrapper {
      .investor-card-outer {
        &:first-child {
          border-top: none !important;
        }
      }
    }

    .left-block {
      .proptech-tab-heading {
        .has-direction-row {
          flex-direction: row !important;
        }
      }
    }
  }
}

.investor-radio-wrapper {
  padding: 5.75px 25px 5.75px 51px;

  @include bp(desk-max) {
    padding: 18px 25px;
  }

  @include bp(xlg-max) {
    padding: 15px 20px;
  }

  @include bp(sm-max) {
    padding: 15px;
  }

  .MuiFormControl-root {
    @include bp(xsm-max) {
      flex-direction: column !important;
      align-items: flex-start !important;
    }

    .MuiFormLabel-root {
      @include bp(xsm-max) {
        margin: 0 0 8px 8px !important;
      }
    }
  }

  .MuiFormControlLabel-root {
    @include bp(xsm-max) {
      flex: 0 0 50%;
      max-width: 50%;
    }

    .MuiRadio-root {
      padding: 8px;
    }

    .MuiSvgIcon-root {
      width: 15px;
      height: 15px;
      fill: #111D43;
    }

    .MuiFormControlLabel-label {
      font-size: 14px;
      font-weight: 300;
      font-family: 'Poppins';
      text-transform: capitalize;
    }

    +.MuiFormControlLabel-root {
      margin-left: 8px !important;

      @include bp(xsm-max) {
        margin-left: 0 !important;
      }
    }
  }
}