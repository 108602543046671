@import '../../../assets/scss/break-points.scss';

.preview-screen {
    .proptech-detail-wrapper {
        .top-menu {
            padding-top: 28px;

            @include bp(md-max) {
                padding-top: 16px;
                padding-bottom: 16px;
            }
        }
    }

    .protech-detail-inner {
        .tab-wrapper {
            height: 100%;
        }

        .tab-panels {
            min-height: 730px;

            @include bp(md-max) {
                min-height: inherit;
                height: auto;
            }

            .tabpanel-wrapper {
                height: 100%;

                .video-wrapper {
                    margin-top: 0;
                    display: flex;
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                }
            }
        }


    }

    .raise-detail-wrapper {

        @media(min-height:950px) and (min-width:992px) {
            // position: sticky;
            top: 80px !important;
            // margin-top: 20px
        }
    }
}