@import '../../../assets/scss/break-points.scss';

.featured-carousel {
    margin-bottom: 32px;
    @include bp(sm-max) { 
        margin-bottom: 20px;
        padding-bottom: 60px;
    }
    .swiper-button-next,
    .swiper-button-prev {
        top: 0;
        transform: none;
        width: 273px;
        height: 100%;
        margin-top: 0;
        @include bp(lg-desk-max) {
            width: 160px;
        }
        @include bp(sm-max) {
            width: 50px;
            background: transparent !important;
            bottom: 0;
            top: auto;
            height: 40px;
            width: 40px;
        }
        &:after {
            font-size: 0;
            background-size: 15px;
            background-repeat: no-repeat;
            width: 40px;
            height: 40px;
            top: 50%;
            transform: translateY(-50%);
            background-color: rgb(255, 255, 255);
            border-radius: 50%;
            box-shadow: 0px 8px 32px rgba(17, 29, 67, 0.04);
            background-position: center;
            position: absolute;
            content: '';
            right: 50px;
            @include bp(sm-max) {
                right: 0;   
              }
        }

        &.swiper-button-disabled {
            display: none;
            @include bp(sm-max) {
                display: block;
                opacity: 0.6;
            }
        }
    }

    .swiper-button-next {
        right: -48px;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(229, 229, 229, 1) 100%);
        &:after {
            background-image: url('../../../assets/Icons/ic-slider-next.svg');
        }
        @include bp(sm-max) {
          right: 0; 
          right: calc(50% - 25px);
          transform: translateX(50%);  
        }
    }

    .swiper-button-prev {
        right: auto;
        left: -48px;
        @include bp(sm-max) {
            right: auto;
            left: calc(50% - 25px);
            transform: translateX(-50%);
        }
        background: linear-gradient(90deg, rgba(229, 229, 229, 1) 0%, rgba(255, 255, 255, 0) 100%);
        &:after {
            background-image: url('../../../assets/Icons/ic-slider-previous.svg');
            left: 50px;
            right: auto;
            @include bp(sm-max) {
                right: auto;
                left: 0;   
            }
        }
    }

    .swiper-wrapper {
        .swiper-slide {
            height: auto;
            width: 416px;
            @include bp(xlg-max) {
                width: 300px;
            }
            .slide-item {
                height: 100%;
            }
        }
    }
}