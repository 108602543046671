@import '../../../assets/scss/colors.scss';
@import '../../../assets/scss/break-points.scss';
.thanks-page {
  .background-blue {
    background-image: url(../../../assets/Icons/ic-rectangle.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 0;
    height: 100%;
    position: fixed;
    left: 0;
    width: calc(100% - 29px);
    top: 0;
    bottom: 0;
    max-height: 100vh;
    @include bp(md-max) {
      position: relative;
      top: 0;
      height: auto;
      background-image: url(../../../assets/Icons/ic-rectangle-down.svg);
      min-height: 250px;
      padding-bottom: 50%;
      background-position: center bottom;
      width: 100%;
      padding-right: 0;
      
    }
  }

  .background-blue:after {
    background-image: url(../../../assets/Icons/ic-tringle.svg);
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
    right: 0;
    @include bp(md-max) {
      background-image: url(../../../assets/Icons/ic-triangle-down.svg);
      bottom: 0;
      background-size: cover;
      background-position: center bottom;
    }
  }
  

.not-have-account{
  z-index: 9;
  padding-right: 44px !important;
  @include bp(md-max) {
    padding-right: 15px !important;
    max-width: 500px;
    padding-bottom: 30px;
    margin-top: 30px;
  }
  p,a{
      font-family: 'Poppins';
      color: #E7E8EC;
      text-align: center;
      font-size: 16px;
      line-height: 1.5;
      @include bp(md-max) {
        color: $black;
      }
  }
  p{
      font-weight: 400;
      padding-bottom: 10px;
  }
  a{
      font-weight: 600;
  }
}
.thanks-wrapper{
  z-index: 9;
  max-width: 432px;
  margin: 0 auto;
  padding-right: 29px;
  
  @include bp(md-max) {
    padding: 30px 15px 0;
    flex: 0 0 100%;
    max-width: 100%;
    .thanks-inner {
      max-width: 500px;
    }
  }
  img{
      margin: 0 auto 64px;
      @include bp(md-max) {
        position: absolute;
        top: 16px;
        left: 50%;
        transform: translateX(-50%);
        margin-bottom: 0;
      }
  }
  *{
      text-align: center;
      margin: 0 auto;
      font-family: 'Poppins';
      color: #ffffff;
      
  }
  h2{
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 1.5;
      padding-bottom: 16px;
      @include bp(md-max) {
        color: $black;
      }
  }
  p{
      font-weight: 400;
      font-size: 16px;
      color: #E7E8EC;
      padding-bottom: 25px;
      line-height: 1.5;
      @include bp(md-max) {
        color: $black;
      }
  }
}
}