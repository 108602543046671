@import '../../../../assets/scss/break-points.scss';

.admin-proptech-tab {
  &.investor-proptech-tab {
    .right-block {
      .right-top-heading {

        @include bp(xsm-max) {
          align-items: flex-start !important;
          justify-content: center !important;
        }
      }
    }

    .investor-card-wrapper {
      .investor-card-outer {
        &:first-child {
          border-top: none !important;
        }
      }

      height: calc(100% - 42.5px);
      background-color: rgba(231, 232, 236, 0.75);
      overflow: auto;

      @include bp(desk-max) {
        height: calc(100% - 67px);
      }

      @include bp(xlg-max) {
        height: calc(100% - 61px);
      }

      @include bp(sm-max) {
        height: calc(100% - 151px);
      }
    }

    .left-block {
      .proptech-tab-heading {
        .has-align-start {
          @include bp(xlg-max) {
            align-items: flex-start !important;
            flex: 0 0 50%;
            max-width: 50%;
          }
        }
      }
    }
  }
}

.investor-radio-wrapper {
  .MuiFormControl-root {
    @include bp(xsm-max) {
      flex-direction: column !important;
      align-items: flex-start !important;
    }

    .MuiFormLabel-root {
      @include bp(xsm-max) {
        margin: 0 0 8px 8px !important;
      }
    }
  }

  .MuiFormControlLabel-root {
    @include bp(xsm-max) {
      flex: 0 0 50%;
      max-width: 50%;
    }

    .MuiRadio-root {
      padding: 8px;
    }

    .MuiSvgIcon-root {
      width: 15px;
      height: 15px;
      fill: #fff;
    }

    .MuiFormControlLabel-label {
      font-size: 14px;
      font-weight: 300;
      font-family: 'Poppins';
      text-transform: capitalize;
    }

    +.MuiFormControlLabel-root {
      margin-left: 8px !important;

      @include bp(xsm-max) {
        margin-left: 0 !important;
      }
    }
  }
}