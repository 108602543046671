@import '../../../assets/scss/colors.scss';
@import '../../../assets/scss/break-points.scss';

.meeting-tab-wrapper {
  .MuiBox-root {
    padding: 0;
  }

  .event-outer {
    padding-top: 30px;

    .title-txt {
      color: $primary;
      font-weight: 600;
      font-size: 16px;
      line-height: 1;
      font-family: 'Poppins';
      display: flex;
      align-items: center;
      padding-bottom: 24px;

      .counter-badge {
        margin-left: 6px;
        border-radius: 58px;
        background-color: $primary;
        padding: 2px 8px;
        font-weight: 600;
        font-size: 12px;
        line-height: 1.6;
        display: inline-flex;
        height: 18px;
        color: #ffffff;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .MuiTabs-root {
    margin: 0 -10px;

    @include bp(sm-max) {
      margin-bottom: -20px;
    }

    .MuiTab-root {
      padding: 0 10px;
      font-family: 'Poppins';
      font-weight: 400;
      font-size: 24px;
      line-height: 1.5;
      color: $gray_1;
      text-transform: capitalize;
      margin-right: 6px;

      @include bp(xlg-max) {
        font-size: 20px;
      }

      @include bp(sm-max) {
        font-size: 18px;
      }

      &.Mui-selected {
        font-weight: 600;
        color: $primary;
      }
    }

    .MuiTabs-indicator {
      display: none;
    }
  }
}

#more-menu {
  border-radius: 8px;
  padding: 8px 14px;

  &:hover {
    background-color: $gray;
  }

  &.open-more {
    background-color: $gray;
  }
}

#more-btn {
  .MuiList-root {
    background: $white;
    border-radius: 8px;
    min-width: 205px;

    .MuiMenuItem-root {
      padding: 17px 20px;
      font-weight: 600;
      font-size: 14px;
      line-height: 1;
      color: $primary;
      font-family: 'Poppins';
    }
  }
}

.event-item {
  background: $white;
  padding: 20px 30px 20px 24px;
  box-shadow: 0px 8px 32px rgba(17, 29, 67, 0.04);
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 20px;

  @include bp(desk-max) {
    padding: 20px 15px;
  }

  @include bp(xlg-max) {
    padding: 15px;
    position: relative;
  }

  .left_col {
    padding-right: 15px;
    flex: 0 0 60%;
    max-width: 60%;

    .card-info {
      max-width: calc(100% - 82px);
      flex: 0 0 calc(100% - 82px);

      @include bp(xsm-max) {
        max-width: 100%;
        flex: 0 0 100%;
      }
    }

    .badge {
      @include bp(xlg-max) {
        position: absolute;
        right: 15px;
        top: 15px;
      }
    }

    @include bp(desk-max) {
      max-width: 50%;
      flex: 0 0 50%;
    }

    @include bp(xlg-max) {
      max-width: 100%;
      flex: 0 0 100%;
      padding: 0 100px 20px 0;
    }

    @include bp(xsm-max) {
      padding-right: 0;
    }
  }

  .right_col {
    flex: 0 0 40%;
    max-width: 40%;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @include bp(desk-max) {
      max-width: 50%;
      flex: 0 0 50%;
    }

    @include bp(xlg-max) {
      max-width: 100%;
      flex: 0 0 100%;
      justify-content: flex-start;
      flex-wrap: wrap;

    }

    #more-menu {
      min-width: 32px;

      @include bp(xlg-max) {
        position: absolute;
        right: 10px;
        top: 15px;
      }

    }

    .MuiButton-root.btn {
      margin-right: 12px;
      font-size: 14px;
      height: 32px;
      line-height: 32px;

      @include bp(xlg-max) {
        &:nth-last-child(2) {
          margin-right: 0;
        }
      }

      @include bp(xsm-max) {
        font-size: 12px;
        padding: 0 10px;
      }

      @include bp(xxsm-max) {
        max-width: 100%;
        flex: 0 0 100%;
        margin-right: 0;
        margin-bottom: 10px;

        &:nth-last-child(2) {
          margin-bottom: 0;
        }
      }
    }
  }

  .nav-top {
    align-items: center;

    h2 {
      display: inline-flex;
      align-items: center;

      span {
        margin-left: 16px;
      }
    }

    .image-wrapper {
      height: 58px;
      width: 58px;
    }

    .listing-item {
      padding: 0;

      li {
        margin: 0;
        color: $gray_1;
        font-weight: 400;
      }
    }
  }
}

.review-meeting {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  p {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 12px;
    line-height: 1.5;
    color: $gray_1;
  }

  .review-btn {
    background: $gray_8;
    height: 32px;
    width: 32px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    margin-left: 8px;

    &.dislike-btn {
      transform: rotate(-180deg);
      -o-transform: rotate(-180deg);
      -ms-transform: rotate(-180deg);
      -webkit-transform: rotate(-180deg);

      svg {
        path {
          fill: $gray_1;
        }
      }

      &.submit-review {
        background-color: $gray_6;

        svg {
          path {
            fill: $red;
          }
        }
      }
    }

    &.like-btn {

      svg {
        path {
          fill: $gray_1;
        }
      }

      &.submit-review {
        background-color: $green_1;

        svg {
          path {
            fill: $green;
          }
        }
      }
    }
  }
}

.meeting-modal {
  * {
    font-family: 'Poppins' !important;
  }

  .MuiBox-root {
    max-width: 460px !important;

    @include bp(sm-max) {
      max-width: calc(100% - 30px) !important;
    }
  }

  .form-wrapper {
    padding-top: 30px;

    .form-group {
      margin-top: 39px !important;
      margin-bottom: 29px !important;

      @include bp(sm-max) {
        margin-top: 16px !important;
        margin-bottom: 16px !important;
      }

      .form-control {
        &.textarea-input {
          .MuiOutlinedInput-input {
            height: 98px !important;
          }
        }
      }
    }
  }

  .btn-wrapper {

    .btn {
      font-size: 16px;
      line-height: 44px;
      height: 44px;

      @include bp(sm-max) {
        font-size: 14px;
        line-height: 32px;
        height: 32px;
      }
    }

  }

  .review-meeting {
    justify-content: center;

    .review-btn {
      height: 64px;
      width: 64px;
      border-radius: 16px;
      margin-right: 16px;
      margin-left: 0;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .badge-wrapper {
    text-align: center;
    margin-top: 41px;

    .badge {
      padding: 8px 16px !important;
      margin: 8px 6px;
      border-color: $gray !important;

      &.green-badge {
        border-color: $green !important;
      }

      &.red-badge {
        border-color: $red !important;
      }
    }
  }
}

.close-btn {
  position: absolute !important;
  right: 15px;
  top: 15px;
  z-index: 11;
}

.meeting-cancel-modal {
  .MuiBox-root {
    max-width: 460px !important;

    @include bp(sm-max) {
      max-width: calc(100% - 30px) !important;
    }
  }

  p {
    font-size: 14px;
    color: $gray_1;
    margin-top: 23px;
  }

  &.invite-modal {
    p {
      margin-top: 0px;
    }
  }

  * {
    font-family: 'Poppins' !important;
  }

  .form-wrapper {
    padding-top: 7px;

    .form-group {
      margin-bottom: 30px !important;

      @include bp(sm-max) {
        margin-bottom: 16px !important;
      }

      .form-control {
        &.textarea-input {
          .MuiOutlinedInput-input {
            height: 98px !important;
          }
        }
      }
    }
  }

  .btn-wrapper {

    .btn {
      font-size: 16px;
      line-height: 44px;
      height: 44px;

      @include bp(sm-max) {
        font-size: 14px;
        line-height: 32px;
        height: 32px;
      }
    }

  }
}

.thank-you-modal {
  .MuiBox-root {
    max-width: 560px !important;

    @include bp(sm-max) {
      max-width: calc(100% - 30px) !important;
    }
  }

  p {
    font-size: 14px;
    color: $gray_1;
    margin-top: 23px;
    margin-bottom: 34px;

    @include bp(sm-max) {
      margin: 20px 0;
    }
  }

  .btn-wrapper {

    .btn {
      font-size: 16px;
      line-height: 44px;
      height: 44px;

      @include bp(sm-max) {
        font-size: 14px;
        line-height: 32px;
        height: 32px;
      }
    }

  }
}

.badge-checkbox-list-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -6px -16px;
  justify-content: center;

  li {
    padding: 0 6px;
    margin-bottom: 16px;

    label {
      position: relative;

      .form-checkbox {
        opacity: 0;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        visibility: hidden;
      }

      .Mui-checked {
        &.red-check {
          +span {
            color: $red;
            border-color: $red;
            background-color: $gray_6;
          }
        }

        +span {
          color: $green;
          background-color: $green_1;
          border-color: $green;
        }
      }

      span {
        line-height: 1;
        font-size: 14px;
        color: $primary;
        font-family: "Poppins";
        font-weight: 600;
        padding: 0 16px;
        min-height: 30px;
        line-height: 30px;
        border-radius: 50px;
        border: 1px solid $gray_9;
        min-width: 71px;
        transition: all 0.5s;
      }
    }
  }
}