@import '../../../assets/scss/colors.scss';
@import '../../../assets/scss/break-points.scss';

.navigation-outer-header {
    z-index: 1;
    position: sticky;
    top: 0;
    margin: 0 -25px;
    padding: 0 25px;
    width: calc(100% + 50px) !important;

    @include bp(md-max) {
        flex-wrap: wrap;
        position: relative;
        margin: 0;
        padding: 0;
        width: 100% !important;

        .proptech-count-wrapper {
            order: 2;
            max-width: 100%;
            flex: 0 0 100%;
        }
    }

    @include bp(desk-min) {
        top: -1px;
    }
}

.navigation-header {
    display: flex;
    align-items: center;
    padding: 0 15px;

    @include bp(md-max) {
        justify-content: flex-end;
        order: 1;
        padding: 0;
        max-width: 100%;
        flex: 0 0 100%;
    }

    @include bp(sm-max) {
        flex-wrap: wrap;
        justify-content: flex-start;
        margin-left: -13px;
        padding-bottom: 15px;

        >div {
            max-width: 33.33%;
            flex: 0 0 33.33%;
        }
    }

    @include bp(xsm-max) {
        >div {
            max-width: 50%;
            flex: 0 0 50%;
        }
    }

    @include bp(xxsm-max) {
        >div {
            max-width: 100%;
            flex: 0 0 100%;

            +div {
                margin-top: 5px;
            }
        }
    }
}

.last-menu {
    border-left: 1px solid rgba($primary, 0.2);
    padding-left: 10px;

    @include bp(md-max) {
        border-left: 0;
        padding-left: 0;
    }
}

.nav-menu {
    position: relative;

    .text {
        position: absolute;
        opacity: 0;
    }

    .MuiIconButton-root,
    .text {
        color: $primary;
        font-family: 'Poppins';
        font-weight: 600;
        font-style: normal;
        font-size: 14px;
        padding: 5px 16px;
        transition: none;

        &:hover,
        &:focus {
            background: transparent !important;
        }

        &.open-nav-menu {
            background: $gray;
            border-radius: 8px;
        }
    }

    .navigation-item {
        img {
            margin-left: 15px;
        }
    }
}

.range_dropdown_menu {
    .MuiSlider-markLabel {
        font-family: 'Poppins';
    }

    .MuiMenuItem-root {
        display: block;
        padding: 32px 24px 0;
        width: 360px;

        @include bp(xxsm-max) {
            width: 90vw;
            padding: 15px 15px 0;

        }

        &:hover {
            background-color: transparent;
        }
    }

    .btn-wrapper {
        padding: 24px 0 8px;
        justify-content: space-between;

        @include bp(xxsm-max) {
            align-items: flex-start;
            flex-direction: column;
        }

        span {
            color: $gray_1;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 1.5;

            @include bp(xxsm-max) {
                margin-bottom: 10px;
            }
        }
    }
}

.rangeSlider {
    // padding: 0 5px;
    margin: 0 auto;
    padding-right: 25px;
    padding-left: 18px;

    @include bp(xxsm-max) {
        width: 100% !important;
    }

    .MuiSlider-root {
        color: $white_gray;
    }

    .MuiSlider-track {
        color: #FFA283;
    }

    .MuiSlider-thumb {
        background: $secondary;
    }
}

.range-wrapper {
    .MuiTouchRipple-root {
        display: none !important;
    }
}

.slider-text {
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
        color: $primary;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 1.5;
        padding: 7px;
        border: 1px solid $gray_7;
        border-radius: 8px;
        min-width: 100px;
    }
}

// Modal css
.MuiModal-root.modal {

    // position: relative;
    .MuiBox-root {
        box-shadow: 0px 12px 32px rgba(17, 29, 67, 0.12);
        border-radius: 16px;
        background: $white;
        border: none;
        max-width: 570px;
        width: 100%;
        max-height: 80%;
        overflow: auto;

        @include bp(sm-max) {
            padding: 15px;
            max-width: calc(100% - 30px);
        }
    }

    h2 {
        color: $primary;
        font-family: 'Poppins';
        font-weight: 600;
        font-size: 24px;
        line-height: 1.5;

        @include bp(sm-max) {
            font-size: 20px;
        }
    }

    .btn-wrapper {
        background: $white;
        z-index: 2;
        padding: 0;

        .disable-btn {
            color: $white;
            background-color: $gray_9;
        }
    }
}

// Modal css
.invite-modal {
    .MuiBox-root {
        max-width: 464px;
    }

    .btn-wrapper {
        .MuiButton-root {
            &.btn {
                height: 44px;
            }
        }
    }
}

.more-filter-modal {
    .filter-wrapper {
        max-height: 370px;
        height: 370px;
        overflow: auto;
        margin-bottom: 10px;
    }

    .btn-wrapper {
        justify-content: space-between;
        background: $white;
        z-index: 2;

        @include bp(xxsm-max) {
            .btn {
                padding: 0;
                margin-right: 5px;
            }
        }
    }

    .filter-item {
        margin-top: 32px;
        padding-bottom: 12px;
        border-bottom: 1px solid $white_gray;

        @include bp(sm-max) {
            margin-top: 20px;
        }

        h2 {
            font-size: 16px;
            line-height: 1;
            padding-bottom: 28px;

            @include bp(sm-max) {
                padding-bottom: 15px;
            }
        }

        ul {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: wrap;

            li {
                flex: 0 0 50%;
                max-width: 50%;
                padding-bottom: 12px;
                text-transform: capitalize;

                &.show-more a {
                    display: inline-flex;
                    align-items: center;
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 1;
                    color: $primary;
                    text-decoration-line: underline;

                    img {
                        margin-left: 10px;
                    }
                }
            }
        }
    }
}