@import "../../../../assets/scss/colors.scss";
@import "../../../../assets/scss/break-points.scss";

.analysis-wrapper {
  padding: 5.3% 3% 30px 8.2%;

  .MuiFormControl-root {
    label {
      font-size: 10px !important;
      text-transform: uppercase;
      color: $primary !important;
      opacity: 0.6;
      font-weight: 600;
      font-family: 'Poppins', sans-serif;

    }

    input {
      font-size: 14px;
      font-family: 'Poppins', sans-serif;
      color: $primary;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    textarea {
      font-size: 14px;
      font-family: 'Poppins', sans-serif;
      color: $primary;
    }
  }

  @include bp(xlg-max) {
    padding: 20px 20px 0;
  }

  @include bp(sm-max) {
    padding: 15px;
  }

  .form-item-wrap {
    @include bp(xsm-max) {
      flex-wrap: wrap;
    }

    >span,
    .MuiTextField-root:first-child {
      padding-right: 10px;
      max-width: 41%;
      flex: 0 0 41%;
      font-size: 14px;
      color: $primary;

      @include bp(xsm-max) {
        max-width: 100%;
        flex: 0 0 100%;
        padding-right: 0;
        margin-bottom: 10px;
      }
    }

    >div {
      max-width: 59%;
      flex: 0 0 59%;

      @include bp(xsm-max) {
        max-width: 100%;
        flex: 0 0 100%;
        justify-content: flex-start !important;
      }

      label {
        +div {
          // margin-top: 5px;
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: 25px;
    }
  }

  .analysis-tab-heading {
    margin-bottom: 50px;

    @include bp(sm-max) {
      margin-bottom: 20px;
    }

    >div {
      &:not(:last-child) {
        margin-right: 15px;
      }
    }
  }

  .company-two-col-block {
    @include bp(sm-max) {
      flex-wrap: wrap;
    }

    .left-form-block {
      max-width: 65%;
      flex: 0 0 65%;
      padding-right: 40px;

      @include bp(xlg-max) {
        padding-right: 20px;
      }

      @include bp(sm-max) {
        max-width: 100%;
        flex: 0 0 100%;
        padding-right: 0;
        margin-top: 20px;
        order: 2;
      }
    }

    .right-image-block {
      max-width: 35%;
      flex: 0 0 35%;

      .inner-image-block {
        border-radius: 12px;
        position: relative;
        overflow: hidden;
        height: 284px;

        @include bp(sm-max) {
          padding-bottom: 109.5%;
          height: auto;
        }

        img {
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          border-radius: 12px;
          object-fit: cover;
        }
      }
    }
  }
}

.bottom-btn-wrapper {
  margin-top: 28px;

  @include bp(xlg-max) {
    margin-top: 40px;
  }

  @include bp(sm-max) {
    margin-top: 25px;
  }

  button {
    font-family: "Poppins", sans-serif;
    font-size: 10px;
    font-weight: 600;
  }
}

.url-list {
  margin: 42px 0 0;

  .title {
    font-size: 14px;
    margin-bottom: 16px;
    color: $primary;
  }

  &.half-width {
    max-width: 57%;
    padding-right: 52px;

    @include bp(desk-max) {
      padding-right: 20px;
    }

    @include bp(sm-max) {
      max-width: 100%;
    }
  }

  @include bp(sm-max) {
    margin-top: 35px;
  }

  .url-list-item {
    label {
      +div {
        margin-top: 22px;

        @include bp(xlg-max) {
          margin-top: 15px;
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: 40px;

      @include bp(xlg-max) {
        margin-bottom: 30px;
      }

      @include bp(sm-max) {
        margin-bottom: 20px;
      }
    }
  }

  .fetch-url-button {
    display: flex;
  }
}

.analysis-rating-chart-block {
  margin-bottom: 50px;

  @include bp(sm-max) {
    margin-bottom: 30px;
    flex-wrap: wrap;

  }

  .rating-block {
    max-width: 57%;
    flex: 0 0 57%;
    padding-right: 52px;

    @include bp(desk-max) {
      padding-right: 20px;
    }

    @include bp(sm-max) {
      max-width: 100%;
      flex: 0 0 100%;
      margin-top: 20px;
      order: 2;
      padding-right: 0;
    }

    .rating-items {
      @include bp(desk-max) {
        flex-direction: column;
      }

      @include bp(sm-max) {
        flex-direction: row;
      }

      @include bp(xsm-max) {
        flex-direction: column;
      }

      &:not(:last-child) {
        margin-bottom: 22px;

        @include bp(desk-max) {
          margin-bottom: 14px;
        }
      }

      span {
        &:not(.rating-title) {
          font-size: 20px;
        }
      }

      .rating-title {
        font-size: 14px;
        color: $primary;
      }
    }

    .last-rating-items {
      @include bp(desk-max) {
        flex-direction: column;
      }

      @include bp(sm-max) {
        flex-direction: row;
      }

      @include bp(xsm-max) {
        flex-direction: column;
      }

      &:not(:last-child) {
        margin-bottom: 2px;

        // @include bp(desk-max) {
        //     margin-bottom: 14px;
        // }
      }

      span {
        &:not(.rating-title) {
          font-size: 20px;
        }
      }

      .rating-title {
        font-size: 14px;
        color: $primary;
      }
    }

    p {
      @include bp(xsm-max) {
        text-align: left !important;
      }

      @include bp(desk-max) {
        text-align: left !important;
      }
    }
  }

  .chart-block {
    max-width: 43%;
    flex: 0 0 43%;

    @include bp(sm-max) {
      max-width: 100%;
      flex: 0 0 100%;

    }

    >div {
      border-radius: 12px;
      min-height: 250px;
    }
  }
}

.analysis-dropdown-wrapper {
  margin: 0 -22px;
  margin-bottom: 16px !important;

  .title {
    font-size: 14px;
    margin-bottom: 16PX;
    text-transform: uppercase;
    color: $primary;
  }

  @include bp(sm-max) {
    margin: 0 -12px;
  }

  @include bp(xsm-max) {
    margin: 0;
    flex-wrap: wrap;
  }

  &.horizontal {
    margin: 0;

    .dropdown-items {
      max-width: calc(100% + 44px);
      flex: 0 0 calc(100% + 44px);
      margin: 0 -22px;
      padding: 0;

      @include bp(sm-max) {
        margin: 0 -12px;
        max-width: calc(100% + 24px);
        flex: 0 0 calc(100% + 24px);

        @include bp(xsm-max) {
          margin: 0;
          flex-wrap: wrap;
          max-width: 100%;
          flex: 0 0 100%;
        }
      }

      // &:last-child {
      //     .dropdown-inner-item {
      //         margin-bottom: 0;

      //         @include bp(xsm-max) {
      //             padding: 0;
      //             margin-bottom: 15px;
      //         }
      //     }
      // }

      .dropdown-inner-item {
        padding: 0 22px;
        max-width: 33.33%;
        flex: 0 0 33.33%;
        margin-bottom: 25px;

        @include bp(sm-max) {
          padding: 0 12px;
          margin-bottom: 15px;

          @include bp(xsm-max) {
            padding: 0;
            max-width: 100%;
            flex: 0 0 100%;

          }
        }

        &:last-child {}

        >div {
          width: 100%;
        }
      }

      .dropdown-inner2-item {
        padding: 0 22px;
        max-width: 66.66%;
        flex: 0 0 66.66%;
        margin-bottom: 25px;

        @include bp(sm-max) {
          padding: 0 12px;
          margin-bottom: 15px;

          @include bp(xsm-max) {
            padding: 0;
            max-width: 100%;
            flex: 0 0 100%;

          }
        }

        &:last-child {}

        >div {
          width: 100%;
        }
      }
    }
  }

  .dropdown-items {
    padding: 0 22px;
    max-width: 33.33%;
    flex: 0 0 33.33%;
    min-height: 46px;
    margin-bottom: 25px;

    @include bp(sm-max) {
      padding: 0 12px;
    }

    @include bp(xsm-max) {
      padding: 0;
      max-width: 100%;
      flex: 0 0 100%;

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }

  .dropdown-title {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 16px;
    color: $primary;

    @include bp(xlg-max) {
      margin-bottom: 20px;
    }

    @include bp(sm-max) {
      margin-bottom: 5px;
    }
  }

  .custom-dropdown-icon {
    &:not(:last-child) {
      margin-bottom: 25px;

      @include bp(sm-max) {
        margin-bottom: 15px;
      }
    }
  }

  .custom-date-picker {
    &:not(:last-child) {
      margin-bottom: 25px;

      @include bp(sm-max) {
        margin-bottom: 15px;
      }
    }
  }
}

.custom-date-picker {
  label {
    font-size: 10px !important;
    text-transform: uppercase;
    color: $primary;
    opacity: 0.6;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    padding-right: 30px !important;
  }

  input {
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    color: $primary;
  }

  .MuiIconButton-root {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 100%;
    margin: 0;
    border-radius: 0;

    svg {
      display: none;
    }

    &:after {
      position: absolute;
      content: '';
      right: 6px;
      top: 2px;
      background-image: url(../../../../assets/Icons/calendar.svg);
      height: 16px;
      width: 16px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 16px;
      transition: all 0.5s;
    }

    .MuiTouchRipple-root {
      display: none;
    }
  }
}