@import "../../../../assets/scss/colors.scss";
@import "../../../../assets/scss/break-points.scss";

.newsfeed-card-details {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 3em;
  box-sizing: border-box;
  background-color: #FFFFFF;
  overflow-y: auto;

  .title {
    font-size: 14px;
    margin-bottom: 16PX;
    text-transform: uppercase;
    color: $primary;
  }

  @include bp(sm-max) {
    margin: 0 -12px;
  }

  @include bp(xsm-max) {
    margin: 0;
    flex-wrap: wrap;
  }

  &.horizontal {
    margin: 0;

    .dropdown-items {
      max-width: calc(100% + 44px);
      flex: 0 0 calc(100% + 44px);
      margin: 0 -22px;
      padding: 0;

      @include bp(sm-max) {
        margin: 0 -12px;
        max-width: calc(100% + 24px);
        flex: 0 0 calc(100% + 24px);

        @include bp(xsm-max) {
          margin: 0;
          flex-wrap: wrap;
          max-width: 100%;
          flex: 0 0 100%;
        }
      }

      .dropdown-inner-item {
        padding: 0 22px;
        max-width: 33.33%;
        flex: 0 0 33.33%;
        margin-bottom: 25px;

        @include bp(sm-max) {
          padding: 0 12px;
          margin-bottom: 15px;

          @include bp(xsm-max) {
            padding: 0;
            max-width: 100%;
            flex: 0 0 100%;

          }
        }

        >div {
          width: 100%;
        }
      }

      .dropdown-inner2-item {
        padding: 0 22px;
        max-width: 66.66%;
        flex: 0 0 66.66%;
        margin-bottom: 25px;

        @include bp(sm-max) {
          padding: 0 12px;
          margin-bottom: 15px;

          @include bp(xsm-max) {
            padding: 0;
            max-width: 100%;
            flex: 0 0 100%;

          }
        }

        >div {
          width: 100%;
        }
      }
    }
  }

  .company-two-col-block {
    min-height: 280px;

    @include bp(sm-max) {
      flex-wrap: wrap;
    }

    .left-form-block {
      max-width: 65%;
      flex: 0 0 65%;
      padding-right: 40px;

      @include bp(xlg-max) {
        padding-right: 20px;
      }

      @include bp(sm-max) {
        max-width: 100%;
        flex: 0 0 100%;
        padding-right: 0;
        margin-bottom: 20px;
      }
    }

    .right-image-block {
      max-width: 35%;
      flex: 0 0 35%;
      @include bp(sm-max) {
        margin-left: auto;
      }
      @include bp(xsm-max) {
        flex: 0 0 100%;
        max-width: 100%;
      }
      .inner-image-block {
        border-radius: 12px;
        position: relative;
        overflow: hidden;
        height: 284px;

        @include bp(sm-max) {
          padding-bottom: 109.5%;
          height: auto;
        }

        img {
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          border-radius: 12px;
          object-fit: cover;
        }
      }
    }
  }

  .form-item-wrap {

    >span,
    .MuiTextField-root:first-child {
      padding-right: 10px;
      font-size: 14px;
      color: $primary;
    }

    >div {
      button {
        @include bp(sm-max) {
          margin-right: 0 !important;
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: 25px;
    }
  }

  .newsfeed-btn-wrapper {
    margin-top: 28px;

    @include bp(sm-max) {
      button {
        margin-bottom: 12px !important;
      }
    }

    @include bp(xlg-max) {
      button {
        margin-bottom: 12px !important;
      }
    }

    @include bp(desk-max) {
      button {
        width: auto !important;
        min-width: 100px !important;
      }
    }

    button {
      font-family: "Poppins", sans-serif;
      font-size: 10px;
      font-weight: 600;
    }
  }
}

.add-newsfeed-modal {
  margin-top: 24px;
  overflow-y: auto;

  .company-two-col-block {
    min-height: 290px;

    @include bp(sm-max) {
      flex-wrap: wrap;
    }

    .left-form-block {
      max-width: 65%;
      flex: 0 0 65%;
      padding-right: 40px;

      @include bp(xlg-max) {
        padding-right: 20px;
      }

      @include bp(sm-max) {
        max-width: 100%;
        flex: 0 0 100%;
        padding-right: 0;
      }
      @include bp(xsm-max) {
        margin-bottom: 20px;
      }
      .form-item-wrap {
        >div {
          max-width: 59%;
          button {
            @include bp(sm-max) {
              margin-right: 0 !important;
            }
          }
        }
      }
    }

    .right-image-block {
      max-width: 35%;
      flex: 0 0 35%;
      @include bp(sm-max) {
        margin-left: auto;
      }
      @include bp(xsm-max) {
        flex: 0 0 100%;
        max-width: 100%;
      }
      .inner-image-block {
        border-radius: 12px;
        position: relative;
        overflow: hidden;
        height: 284px;

        @include bp(sm-max) {
          padding-bottom: 109.5%;
          height: auto;
        }

        img {
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          border-radius: 12px;
          object-fit: cover;
        }
      }
    }
  }
}