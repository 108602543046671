@import "../../../assets/scss/colors.scss";
@import "../../../assets/scss/break-points.scss";

.user-icon {
    width: 38px;
    height: 38px;
    background-color: $white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    @include bp(sm-max) {
        width: 44px;
        height: 44px;
    }

    @include bp(lg-max) {
        width: 32px;
        height: 32px;
    }
}