@import '../../../../assets/scss/break-points.scss';
@import '../../../../assets/scss/colors.scss';

.investor-card-outer {
  .investor-card-inner {
    @include bp(xsm-max) {
      padding: 20px 15px !important;
    }

    .button-wrapper {
      @include bp(xsm-max) {
        display: flex;

        button {
          @include bp(xsm-max) {
            width: inherit !important;
          }

          +button {
            @include bp(xsm-max) {
              margin-left: 10px;
            }
          }
        }
      }
    }

    .investor-details-list {
      li {
        @include bp(xlg-max) {
          flex: 0 0 33.33% !important;
          max-width: 33.33% !important;
        }

        @include bp(xxsm-max) {
          flex: 0 0 50% !important;
          max-width: 50% !important;
        }
      }
    }

    >div {
      @include bp(xsm-max) {
        flex: 0 0 100% !important;
        max-width: 100% !important;
        padding: 0 !important;
      }

      +div {
        @include bp(xsm-max) {
          margin-top: 5px;
        }
      }
    }
  }
}

.investor-card-details-value {
  font-size: 14px;
  line-height: 20px;
  color: $primary;
  text-transform: capitalize;
}