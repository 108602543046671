@import '../../../assets/scss/break-points.scss';

.proptech-signu-nav {
  @include bp(xlg-max) {
    padding: 10px 25px !important;
  }

  @include bp(lg-max) {
    padding: 10px 16px !important;
  }
}

.proptech-dashboard-header {
  @include bp(xlg-max) {
    padding-right: 25px !important;
  }

  @include bp(lg-max) {
    padding-right: 16px !important;
  }

  .proptech-nav-main-title {
    h2 {
      @include bp(xxsm-max) {
        font-size: 16px !important;
      }
    }

    p {
      @include bp(xxsm-max) {
        font-size: 8px !important;
      }
    }
  }
}

.proptech-signup-detail-wrapper {
  padding-top: 96px !important;

  @include bp(xlg-max) {
    padding: 78px 25px 30px !important;
  }

  @include bp(lg-max) {
    padding: 73px 16px 25px !important;
  }

  .boxshadow-box {
    box-shadow: 30px 30px 25px rgba(0, 0, 0, 0.05);
  }

  .left-side-bar {
    padding-right: 28px;
    max-width: 451px;
    flex: 0 0 451px;

    @include bp(xlg-max) {
      padding-right: 25px;
    }

    @include bp(lg-max) {
      padding-right: 16px;
    }

    @include bp(md-max) {
      max-width: 100%;
      flex: 0 0 100%;
      padding: 0 0 15px;
    }

    >div {
      @include bp(xlg-max) {
        padding: 30px 25px !important;
      }

      @include bp(lg-max) {
        padding: 25px 20px !important;
      }

      @include bp(sm-max) {
        padding: 20px 16px !important;
      }
    }
  }

  .right-step-wrapper {
    max-width: calc(100% - 451px);
    flex: 0 0 calc(100% - 451px);
    padding: 6.9% 6% 3.5% !important;
    position: relative;

    @include bp(md-max) {
      max-width: 100%;
      flex: 0 0 100%;
    }

    @include bp(sm-max) {
      padding: 20px 16px !important;
    }

    .MuiStep-root {
      @include bp(xsm-max) {
        .Mui-active {
          display: block;
        }
      }
    }

    .main-title {
      margin-bottom: 60px;

      @include bp(xlg-max) {
        font-size: 28px !important;
        margin-bottom: 30px;
      }

      @include bp(md-max) {
        margin-bottom: 15px;
      }

      @include bp(sm-max) {
        font-size: 24px !important;
        margin-bottom: 0;
      }
    }

    .has-icon-field {
      label {
        padding-right: 25px !important;
        font-size: 14px !important;
        color: #111D43 !important;
        font-weight: 400 !important;
      }
    }

    @include bp(xlg-max) {
      padding: 30px 25px !important;
    }

    @include bp(lg-max) {
      padding: 25px 20px !important;
    }

    .MuiInput-input {
      font-size: 12px;
      font-family: 'Poppins';
      color: #111D43;
    }

    .MuiFormControl-root {
      .info-icon {
        position: absolute;
        top: -20px;
        right: 0;
      }
    }

    .Mui-error {
      font-family: 'Poppins';
    }

    .proptech-stepper {
      .MuiButton-root {
        text-transform: capitalize;
        box-shadow: none;
        font-family: 'Poppins';

        @include bp(sm-max) {
          height: 40px !important;
        }
      }

      .MuiStep-root {
        @include bp(sm-max) {
          padding-left: 3px;
          padding-right: 3px;
        }

        @include bp(xxsm-max) {
          padding-left: 0;
          padding-right: 0;
        }

        .MuiStepLabel-label {
          @include bp(xxsm-max) {
            font-size: 12px;
            margin-top: 6px;
          }
        }

        .MuiStepConnector-root {
          left: calc(-50% + 10px);
          right: calc(50% + 10px);

          .MuiStepConnector-line {
            border-top-width: 2px;
            border-color: rgba(17, 29, 67, 0.1);
          }
        }
      }

      .MuiStepLabel-iconContainer {
        cursor: pointer;

        &:hover {
          +.MuiStepLabel-labelContainer {
            @include bp(xxsm-max) {
              opacity: 1;
              visibility: visible;
            }
          }
        }

        +.MuiStepLabel-labelContainer {
          @include bp(xxsm-max) {
            opacity: 0;
            visibility: hidden;
            transition: 0.3s;
            position: absolute;
            top: 20px;
            width: 100%;
            white-space: nowrap;
          }

          span {
            color: rgba(17, 29, 67, 0.4);
            font-family: 'Poppins';

            &.Mui-active {
              color: #111D43;
            }

            &.Mui-completed {
              color: rgba(17, 29, 67, 0.6);
            }
          }
        }

        .MuiSvgIcon-root {
          text {
            font-size: 10px;
            font-family: 'Poppins';
            font-weight: 600;
          }
        }
      }
    }

    section {
      .MuiFormControl-root {
        @include bp(sm-max) {
          margin-top: 15px !important;
        }

        .MuiInput-root {
          &:before {
            border-color: #70778E;
          }
        }
      }
    }

    &.heading1-block-wrapper {
      .main-title {
        margin-bottom: 90px;

        @include bp(xlg-max) {
          margin-bottom: 60px;
        }

        @include bp(md-max) {
          margin-bottom: 0;
        }
      }

      .MuiInput-input {
        padding: 12px 0;

        @include bp(md-max) {
          padding: 4px 0 5px;
        }
      }

      .MuiFormControl-root {
        @include bp(sm-max) {
          margin-top: 0 !important;
        }
      }
    }

    .brief-overview {
      @include bp(sm-max) {
        margin-top: 25px !important;
      }

      .text-sm {
        font-size: 10px !important;
        text-transform: uppercase;
        font-family: Poppins !important;
        font-weight: 600 !important;
        letter-spacing: 0.1em;
        color: #111D43 !important;
      }
    }

    .select-package-page {
      .main-title {
        @include bp(sm-max) {
          margin-bottom: 15px;
        }
      }

      .package-table {
        box-shadow: none;

        table {
          .feature-info {
            p {
              flex: 1;
            }

            img {
              flex: 0 0 20px;
              max-width: 20px;
            }
          }

          tr {

            th,
            td {
              border-right: 1px solid rgba(112, 119, 142, 0.5);
              border-bottom-color: rgba(112, 119, 142, 0.5);

              &:last-child {
                border-right: none;
              }

              &:first-child {
                padding-left: 0;
                padding-right: 40px;
              }
            }

            &:last-child {

              th,
              td {
                border: none;
              }
            }
          }
        }
      }

      .cost-section {
        .cost-wrapper {
          .cost-inner {
            // flex: 1; 
            align-self: center;
            padding: 15px;

            &.review-title {
              flex: 0 0 225px;
              max-width: 225px;

              @include bp(sm-max) {
                flex: 0 0 100%;
                max-width: 100%;
                text-align: center;
                padding-bottom: 0;
                padding-right: 0;
              }

              >div {
                @include bp(sm-max) {
                  max-width: 100% !important;
                }
              }
            }

            &:last-child {
              @include bp(sm-max) {
                flex: 0 0 100%;
                max-width: 100%;
              }
            }
          }
        }
      }

      .terms-condition-section {
        @include bp(sm-max) {
          flex-direction: column;
        }

        .MuiFormControlLabel-label {
          font-size: 14px;
          line-height: 1.5;
          font-family: 'Poppins';
        }

        >p {
          @include bp(sm-max) {
            text-align: center;
          }
        }
      }
    }

    .custom-dropdown-icon {
      &.proptech-dropdown {
        label {
          opacity: 1;
          font-size: 13px !important;
        }
      }
    }
  }
}

.proptech-dashboard-page {
  @include bp(xlg-max) {
    padding: 30px 13px !important;
  }

  @include bp(lg-max) {
    padding: 25px 4px !important;
  }

  .proptech-dashboard-wrapper {
    position: relative;
    padding-right: 33.33%;

    @include bp(xlg-max) {
      padding-right: 0;
    }

    .dashboard-left-block {
      flex: 0 0 100%;
      max-width: 100%;
      min-height: 100%;

      .dashboard-outer {
        margin: 0 -12px;
        height: 100%;

        .dashboard-inner {
          flex: 0 0 50%;
          min-height: 380px;
          max-width: 50%;

          @include bp(sm-max) {
            flex: 0 0 100%;
            max-width: 100%;
            padding-bottom: 16px !important;
          }

          .mobile-order2 {
            @include bp(sm-max) {
              order: 2;
            }
          }

          .mobile-order3 {
            @include bp(sm-max) {
              order: 3;
            }
          }

          .mobile-order4 {
            @include bp(sm-max) {
              order: 4;
            }
          }
        }
      }
    }

    .dashboard-right-block {
      max-width: 33.33%;
      position: absolute;
      width: 100%;
      right: 0;
      top: 0;
      height: 100%;

      @include bp(xlg-max) {
        position: relative;
        flex: 0 0 100%;
        max-width: 100%;
        margin-top: 32px;
      }

      @include bp(sm-max) {
        margin-top: 0;
      }
    }

    .dashboard-widget {
      @include bp(lg-desk-max) {
        padding: 20px !important;
      }

      .MuiPickerStaticWrapper-root {
        height: 100% !important;
      }

      .MuiPickerStaticWrapper-content {
        background-color: transparent !important;
      }
    }
  }

  .calender-block {
    background-color: rgba(17, 29, 67, 0.1);
    padding-bottom: 0px !important;

    .MuiCalendarPicker-root {
      width: 100%;
      height: 100%;

      div {
        font-family: 'Poppins';
      }

      .css-1dozdou {
        padding: 0;
        max-width: 300px;
        margin: 0 auto 8px;
        width: 100%;

        .PrivatePickersFadeTransitionGroup-root {
          div {
            font-size: 18px;
          }
        }

        .MuiPickersArrowSwitcher-spacer {
          display: none;
        }
      }

      .css-mvmu1r {
        margin: 8px 0;

        .MuiButtonBase-root {
          background-color: transparent;
          border: 1px solid #70778E;
          color: #70778E;
          font-family: 'Poppins';

          &.MuiPickersDay-today {
            background-color: #111D43;
            color: #fff;
            border-color: #111D43;
          }
        }

        .MuiPickersDay-dayWithMargin {
          margin: 0 4px;

          @include bp(xxsm-max) {
            font-size: 10px;
            width: 26px;
            height: 26px;
          }
        }
      }

      .PrivatePickersSlideTransition-root {
        min-height: 272px;
      }

      .MuiTypography-root {
        margin: 0 4px;
        font-family: 'Poppins';
        color: #111D43;
        font-weight: 600;

        @include bp(xxsm-max) {
          width: 26px;
        }
      }
    }
  }

  .metrics-block-wrapper {
    margin: 0 -20px -24px;

    @include bp(desk-max) {
      margin: 0 -10px -20px;
    }

    @include bp(xxsm-max) {
      margin: 0 -5px -10px;
    }

    .metrics-outer {
      flex: 0 0 33.33%;
      max-width: 33.33%;
      margin-bottom: 24px;

      @include bp(desk-max) {
        padding: 0 10px !important;
        margin-bottom: 20px;
      }

      @include bp(xxsm-max) {
        padding: 0 5px !important;
        margin-bottom: 10px;
      }
    }
  }

  .news-feed-wrapper {
    max-height: calc(100% - 46px);
    overflow-y: auto;
    padding: 1px 2px;

    @include bp(xlg-max) {
      max-height: 100%;
      display: flex;
      flex-wrap: wrap;
      margin: 0 -12px -24px;
    }

    .news-feed-outer {
      @include bp(xlg-max) {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 0 12px;
        margin-bottom: 24px;
      }

      @include bp(xsm-max) {
        flex: 0 0 100%;
        max-width: 100%;
      }

      +.news-feed-outer {
        margin-top: 12px;

        @include bp(xlg-max) {
          margin-top: 0;
        }
      }

      .news-feed-inner {
        height: 100%;

        @include bp(sm-max) {
          padding: 15px !important;
        }
      }
    }
  }

  .has-slider {
    .dashboard-widget {
      @include bp(lg-desk-max) {
        padding-right: 0 !important;
      }
    }

    .upcoming-slider {
      position: static !important;
      padding-right: 34px !important;

      @include bp(lg-desk-max) {
        padding-right: 20px !important;
      }

      .swiper-button-next,
      .swiper-button-prev {
        top: 34px;
        width: 25px;
        height: 25px;
        margin-top: 0;

        @include bp(lg-desk-max) {
          top: 23px;
        }

        &:after {
          font-size: 0;
          background-size: 15px;
          background-repeat: no-repeat;
          width: 15px;
          height: 15px;
        }

        &.swiper-button-disabled {
          opacity: 0.6;
        }
      }

      .swiper-button-next {
        right: 34px;

        @include bp(lg-desk-max) {
          right: 20px;
        }

        &:after {
          background-image: url('../../../assets/Icons/ic-slider-next.svg');
        }
      }

      .swiper-button-prev {
        left: auto;
        right: 60px;

        @include bp(lg-desk-max) {
          right: 45px;
        }

        &:after {
          background-image: url('../../../assets/Icons/ic-slider-previous.svg');
        }
      }

      .swiper-wrapper {
        .swiper-slide {
          height: auto;

          .slide-item {
            height: 100%;
          }
        }
      }
    }
  }
}