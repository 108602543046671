@import '../../../../assets/scss/colors.scss';

.team-introduction {
  padding: 8px 0 0;

  .team-info {
    display: flex;
    align-items: center;
    padding: 8px 0;

    i {
      display: inline-flex;
      height: 59px;
      width: 59px;
      padding: 11px;
      margin-right: 16px;
      background: $gray_7;
      border-radius: 6px;
    }

    h3 {
      font-weight: 700;
      font-size: 14px;
      line-height: 1.5;
      color: $primary;

      span {
        font-weight: 400;
        padding-top: 5px;
        display: block;
      }
    }
  }
}

.supporting-award {
  padding-bottom: 32px;

  .menu-item {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -10px;

    li {
      justify-content: center;
      cursor: pointer;
      min-width: 70px !important;
      max-width: 100px !important;
      height: 50px;
      border: 1px solid #E7E8EC;
      border-radius: 54px;
      padding: 4px;
      font-weight: 600;
      font-size: 12px;
      line-height: 1;
      font-family: "Poppins";
      word-break: break-word;
      width: 100%;
      color: #111D43;
      opacity: 1;
      display: inline-flex;
      align-items: center;
      margin-right: 15px;
      margin-bottom: 10px;

      img {
        margin: 0 !important;
        border-radius: 54px;
        width: 100% !important;
        height: 100% !important;
        object-fit: cover;

        @media (min-width: 1500px) {
          width: 100% !important;
        }
      }
    }
  }
}

#map-canvas {
  height: 512px;
  width: 512px;
}

.gmnoprint {
  display: none;
}