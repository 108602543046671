@import '../../../assets/scss/break-points.scss';

.vs-dashboard {
    .left-side-bar {
        padding: 28px;
        max-width: 451px;
        flex: 0 0 451px;

        @include bp(xlg-max) {
            padding: 25px;
        }

        @include bp(lg-max) {
            padding: 16px;
        }

        @include bp(md-max) {
            max-width: 50%;
            flex: 0 0 50%;
            padding: 15px;
        }
    }

    .calender-block {
        background-color: rgba(17, 29, 67, 0.1);
        padding-bottom: 0px !important;

        .MuiCalendarPicker-root {
            width: 100%;
            height: 100%;

            div {
                font-family: 'Poppins';
            }

            .css-1dozdou {
                padding: 0;
                max-width: 300px;
                margin: 0 auto 8px;
                width: 100%;

                .PrivatePickersFadeTransitionGroup-root {
                    div {
                        font-size: 18px;
                    }
                }

                .MuiPickersArrowSwitcher-spacer {
                    display: none;
                }
            }

            .css-mvmu1r {
                margin: 8px 0;

                .MuiButtonBase-root {
                    background-color: transparent;
                    border: 1px solid #70778E;
                    color: #70778E;
                    font-family: 'Poppins';

                    &.MuiPickersDay-today {
                        background-color: #111D43;
                        color: #fff;
                        border-color: #111D43;
                    }
                }

                .MuiPickersDay-dayWithMargin {
                    margin: 0 4px;

                    @include bp(xxsm-max) {
                        font-size: 10px;
                        width: 26px;
                        height: 26px;
                    }
                }
            }

            .PrivatePickersSlideTransition-root {
                min-height: 272px;
            }

            .MuiTypography-root {
                margin: 0 4px;
                font-family: 'Poppins';
                color: #111D43;
                font-weight: 600;

                @include bp(xxsm-max) {
                    width: 26px;
                }
            }
        }
    }

    .vs-widget {
        padding: 28px 28px 28px 0;
        height: 100%;

        @include bp(xlg-max) {
            padding: 25px 25px 25px 0;
        }

        @include bp(lg-max) {
            padding: 16px 16px 16px 0;
        }

        @include bp(md-max) {
            max-width: 50%;
            flex: 0 0 50%;
            padding: 15px;
        }
    }

    .recommendation-slider {
        position: static !important;

        .swiper-button-next,
        .swiper-button-prev {
            top: 34px;
            width: 25px;
            height: 25px;
            margin-top: 0;

            @include bp(lg-desk-max) {
                top: 23px;
            }

            &:after {
                font-size: 0;
                background-size: 15px;
                background-repeat: no-repeat;
                width: 15px;
                height: 15px;
            }

            &.swiper-button-disabled {
                opacity: 0.6;
            }
        }

        .swiper-button-next {
            right: 34px;

            @include bp(lg-desk-max) {
                right: 20px;
            }

            &:after {
                background-image: url('../../../assets/Icons/ic-slider-next.svg');
            }
        }

        .swiper-button-prev {
            left: auto;
            right: 60px;

            @include bp(lg-desk-max) {
                right: 45px;
            }

            &:after {
                background-image: url('../../../assets/Icons/ic-slider-previous.svg');
            }
        }

        .swiper-wrapper {
            .swiper-slide {
                height: auto;

                .slide-item {
                    height: 100%;
                }
            }
        }
    }
}

.venture-scout-dashboard-page {
    @include bp(xlg-max) {
        padding: 30px 13px !important;
    }

    @include bp(lg-max) {
        padding: 25px 4px !important;
    }

    .venture-scout-dashboard-wrapper {
        .dashboard-left-block {
            max-width: 33.33%;
            flex: 0 0 33.33%;

            @include bp(xlg-max) {
                position: relative;
                flex: 0 0 100%;
                max-width: 100%;
                margin-bottom: 32px;
            }

            @include bp(sm-max) {
                margin-bottom: 16px;
            }
        }

        .dashboard-right-block {
            flex: 0 0 66.66%;
            max-width: 66.66%;

            @include bp(xlg-max) {
                flex: 0 0 100%;
                max-width: 100%;
            }

            .dashboard-outer {
                margin: 0 -12px;
                height: 100%;

                .dashboard-inner {
                    flex: 0 0 50%;
                    min-height: 380px;
                    max-width: 50%;

                    @include bp(sm-max) {
                        flex: 0 0 100%;
                        max-width: 100%;
                        padding-bottom: 16px !important;
                    }

                    &.full-width {
                        flex: 0 0 100%;
                        max-width: 100%;
                        padding-bottom: 0 !important;
                    }
                }
            }
        }

        .dashboard-widget {
            @include bp(lg-desk-max) {
                padding: 20px !important;
            }

            .MuiPickerStaticWrapper-root {
                height: 100% !important;
            }

            .MuiPickerStaticWrapper-content {
                background-color: transparent !important;
            }

            .number-wrapper {
                @include bp(xsm-max) {
                    flex-wrap: wrap;
                }

                .main-title {
                    @include bp(xsm-max) {
                        padding-bottom: 10px;
                    }
                }
            }
        }
    }
}