@import '../../../assets/scss/colors.scss';
@import '../../../assets/scss/break-points.scss';

.admin-top-nav {
  padding: 26px 63px;
  @include bp(xlg-max) {
    padding: 20px 25px;
  }
  @include bp(lg-max) {
    padding: 10px 16px;
  }
  @include bp(xxsm-max) {
    padding: 10px 16px 35px;
    position: relative;
  }
  .left-logo-block {
    @include bp(xlg-max) {
      h2 {
        font-size: 26px !important;
      }
    } 
    @include bp(lg-max) {
      padding-left: 30px;
      h2 {
        font-size: 22px !important;
        max-width: 300px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    @include bp(xsm-max) {
      h2 {
        font-size: 18px !important;
        max-width: 220px;
      }
      p {
        font-size: 10px !important;
      }
    }
    @include bp(xxsm-max) {
      h2 {
        position: absolute;
        bottom: 5px;
        left: 0;
        right: 0;
        text-align: center;
        width: 100%;
        max-width: 100%;
        padding: 0 15px;
      }
    }
  }
  .hamburger-menu {
    @include bp(xxsm-max) {
      top: 10px;
    }
    .mobile-menu-open & {
      left: 60px;
    }
  }
}
.navbar-rightside {
  .logout-icon  {
    img {
      @include bp(xxsm-max) {
        width: 20px;
      }
    }
  }
  .notification-icon {
    border-radius: 50%;
    background: white;
    width: 54px;
    height: 54px;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
    @include bp(lg-max) {
      width: 40px;
      height: 40px;
      svg {
        font-size: 24px;
      }
    }
    @include bp(xxsm-max) {
      width: 30px;
      height: 30px;
      svg {
        font-size: 20px;
      }
    }
  }
}