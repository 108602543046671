@import '../../../assets/scss/colors.scss';
@import '../../../assets/scss/break-points.scss';

.company-profile-page {
  max-width: 800px;
  padding: 50px;
  width: 100%;
  padding: 80px 47px;

  @include bp(lg-max) {
    padding: 80px 16px;
  }

  @include bp(sm-max) {
    padding: 20px 16px;
  }

  .MuiFormControl-root {
    label {
      font-size: 10px !important;
      text-transform: uppercase;
      color: $primary !important;
      opacity: 0.6;
      font-weight: 600;
      font-family: 'Poppins', sans-serif;
    }

    input {
      font-size: 14px;
      font-family: 'Poppins', sans-serif;
      color: $primary;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    textarea {
      font-size: 14px;
      font-family: 'Poppins', sans-serif;
      color: $primary;
    }
  }


  h1 {
    font-size: 22px;
    font-weight: 600;
    color: $primary;
    margin-bottom: 20px;
  }

  p {
    font-size: 12px;
    color: $primary;
    font-family: 'Poppins';
  }

  .investor-top-heading {
    display: flex;
    align-items: center;
    margin-bottom: 40px;

    em {
      // height: 32px;
      // width: 60px;
      border-radius: 50%;
      min-width: 32px;

      img {
        border-radius: 20%;
        height: 70px;
        width: 75px;
      }
    }

    .content-block {
      padding-left: 16px;

      h3 {
        font-size: 20px;
        font-weight: 400;
        color: $primary;

        span {
          font-weight: 600;
        }
      }

      p {
        color: $primary;
        opacity: 0.6;
        font-size: 10px;
      }
    }
  }

  .arrow-btn {
    background-color: transparent !important;
    box-shadow: none !important;
    text-decoration: underline;
    color: $primary;
    padding: 0;
    font-size: 12px;
    font-weight: 600;

  }

  .url-list {
    margin-bottom: 40px;
    margin-top: 15px;
  }

  .bottom-btn-wrapper {
    margin-top: 30px;
  }

  .profile-range-wrapper {
    .MuiSlider-markLabel {
      font-family: 'Poppins';
    }

    .MuiSlider-markLabelActive {
      color: #111D43;
    }

    .rangeslider-wrapper {

      .MuiSlider-valueLabel:first-child {
        span {
          height: 0 !important;
          transform: rotate(0deg) translateX(-35%) translateY(5px) !important;
          background-color: white !important;
        }
      }

      +.rangeslider-wrapper {
        margin-top: 25px;
      }

      label {
        font-size: 12px;
        color: $primary;
        font-weight: 600;
        text-transform: uppercase;
        display: block;
        margin-bottom: 10px;
        opacity: 0.4;
      }

      .MuiSlider-root {
        height: 1px;
        color: $primary;
      }

      .MuiSlider-track {
        border-bottom: 0;
      }

      .MuiSlider-thumbColorPrimary {
        height: 16px;
        width: 16px;
      }

      .rangeSlider {
        padding-left: 8px;
        padding-right: 8px;
        width: 100% !important;

        @include bp(md-max) {
          width: calc(100% - 30px) !important;
          margin-left: 0;

          .MuiSlider-markLabel {
            font-size: 10px;
          }
        }
      }
    }
  }

  .investor-dropdown-wrapper {
    margin-top: 40px;

    h2 {
      font-family: 'Poppins';
      font-weight: 600;
      font-size: 16px;
      line-height: 1;
      color: $primary;
      padding-bottom: 16px;
      // text-transform: capitalize;
    }

    .dropdown-title {
      font-size: 14px;
      margin-bottom: 16px;
    }

    .dropdown-items {
      margin: 0 -22px;

      @include bp(sm-max) {
        margin: 0 -12px;
        flex-wrap: wrap;
      }

      >div {
        padding: 0 22px;
        max-width: 50%;
        flex: 0 0 50%;
        margin-bottom: 25px;

        @include bp(sm-max) {
          padding: 0 12px;
          margin-bottom: 15px;

          @include bp(xsm-max) {
            max-width: 100%;
            flex: 0 0 100%;

          }
        }

        &:last-child {}

        >div {
          width: 100%;
        }
      }
    }
  }
}