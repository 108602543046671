.filter-item {
	.form-checkbox {
		&:after {
			width: 18px;
			height: 18px;
			border-radius: 2px;
		}
	}

	.MuiCheckbox-root {
		margin: 0 12px 0 0;
		padding: 0;
	}

	.MuiFormControlLabel-root {
		margin: 0;
	}
}