@import '../../../assets/scss/colors.scss';
@import '../../../assets/scss/break-points.scss';

.venture-scout-tab {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 30px;
    background-color: $white_gray_1;
}

.venture-scout-tab-inner {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    border-radius: 32px;
    overflow: hidden;
    background-color: $white;
}

.venture-scout-right-side-tabpanel {
    width: 100%;
    display: flex;
    align-items: center;
    color: $white;
    background-color: $primary;
}

.venture-scout-right-side-tabpanel-text {
    font-size: 14px;
    line-height: 20px;
    font-style: normal;
    text-transform: uppercase;
    cursor: pointer;
}

.venture-scout-left-side-search-container {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding-left: 15px;
    padding-right: 15px;
    background-color: $white_gray;

    .search {
        height: 30px;
        width: 30px;
        border-radius: 9999px;
        background-color: $white;
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }

    .sort {
        display: flex;
        font-weight: 600;
        font-size: 12px;
        white-space: nowrap;
        font-style: normal;
        text-transform: uppercase;

        .sort-child {
            display: flex;
            cursor: pointer;
            margin-right: 10px;
        }


        .sort-last-child {
            display: flex;
            cursor: pointer;
            margin-right: 0px;
        }
    }
}

.venture-scout-card-container {
    height: 168px;
    overflow-y: auto;
    margin-right: -4px;

    .venture-scout-card-border {
        border-style: solid;
        border-top-width: 1px;
        border-color: $primary;
        opacity: 0.4;
    }

    .venture-scout-card-bg-1 {
        width: 100%;
        cursor: pointer;
        background-color: $white;
    }

    .bg-2 {
        width: 100%;
        cursor: pointer;
        background-color: $reject_gray;
    }

    .bg-3 {
        width: 100%;
        cursor: pointer;
        background-color: $white_gray;
    }

    .profile-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 9999px;
        background-color: $gray_1;
    }

    .name {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        font-style: normal;
        color: $primary;
    }

    .status {
        width: 100%;
        box-sizing: border-box;
        align-items: center;
        display: flex;
        justify-content: flex-end;
    }

    .button-container {
        margin-left: 12px;
        margin-right: 12px;
        border-radius: 12px;
        border-style: solid;
        background-color: $primary;
    }

    .button-container:hover {
        background-color: $primary;
    }

    .button-label {
        font-size: 10px;
        font-weight: 600;
        line-height: 12px;
        text-align: center;
        font-style: normal;
        text-transform: uppercase;
        letter-spacing: 0.05em;
        color: $white;
    }

    .button-label:hover {
        background-color: $primary;
    }
}

.input-field {
    display: none;
}

.profile-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
}

.left-field {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.profile-image {
    display: flex;
    flex-direction: column;
    justify-content: flex-end !important;
    align-items: flex-end !important;
}

.upload-button-label {
    font-size: 10px !important;
    font-weight: 600 !important;
    line-height: 12px !important;
    text-align: center !important;
    font-style: normal !important;
    text-transform: uppercase !important;
    letter-spacing: 0.05em !important;
    color: $primary !important;
}

.upload-button {
    width: 120px !important;
    height: 44px !important;
    border-radius: 12px !important;
    border-style: solid !important;
    border-width: 1px !important;
    border-color: $primary !important;
    background-color: $white !important;
}

.error-message {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    margin: 8px 0px 0px 0px;
    text-align: left;
    letter-spacing: 0.025em;
    color: $error;
}

.submit-button-label {
    font-size: 10px !important;
    font-weight: 600 !important;
    line-height: 12px !important;
    text-align: center !important;
    font-style: normal !important;
    text-transform: uppercase !important;
    letter-spacing: 0.05em !important;
    color: $white !important;
}

.submit-button {
    width: 142px !important;
    height: 44px !important;
    border-radius: 12px !important;
    border-style: solid !important;
    background-color: $primary !important;
}