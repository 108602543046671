$black: #000000;
$white: #ffffff;

$primary: #111D43;
$secondary: #FF4100;

$dark-black: #2C3B6A;
$orange: #FFA283;
$blue: #2DBBDA;

$red: #DE2828;
$white_gray: #E7E8EC;
$yellow: #FFC90A;
$yellow_1: #FFD84F;
$gray: #C9CDD5;
$gray_1: #70778E;
$gray_2: #bbc0d1;
$gray_3: #FFEDE6;
$gray_4: #C3CBD4;
$gray_5: #626D7C;
$gray_6: #FCE7E7;
$gray_7: #E6E7E8;
$gray_8: #F7F7F7;
$gray_9: #C9CDD4;
$green: #39B54A;
$green_1: #ECF8ED;
$orange: #F79C15;
$lemon_yellow: #CBE141;
$white_gray_1: #FAFAFB;
$reject_gray: #DFEEE2;
$error: #d32f2f;