@import "../../../assets/scss/colors.scss";
@import "../../../assets/scss/break-points.scss";

.featured-card-wrapper {
  min-height: 197px;
  border-radius: 16px;
  background-color: $white;
  .bottom-menu-card {
    .menu-item  {
      flex-wrap: wrap;
      margin-bottom: -10px;
      li {
        margin-bottom: 10px;
        max-width: 112px;
        min-width: 112px;
        &:last-child {
          margin-right: 0;
        }
        img {
          min-width: 30px;
          width: 30px;
        }
      }
    }
  }
  .top-featured {
    background: linear-gradient(to right, #ff794b 0%, #ff4302 100%);
    border-radius: 16px 16px 0 0;
    padding: 2px 6px;
    span {
      font-family: 'Poppins';
      font-weight: 600;
      font-size: 12px;
      color: $white;
      margin-left: 25px;
      @include bp(xlg-max) {
        margin-left: 10px;
      }
    }

  }

  .company-detail-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 16px 25px 10px;
    position: relative;
    @include bp(xlg-max) {
      flex-wrap: wrap;
      margin: 15px 15px 10px;
    }
    .right-link {
      @include bp(xlg-max) { 
        position: absolute;
        right: 0;
        top: 0;
      }
    }
    .left-content {
      @include bp(xlg-max) {
        order: 2;
        max-width: 100%;
        flex: 0 0 100%;
        flex-wrap: wrap;
      }
    }
    .company-logo {
      margin-right: 24px;

      img,
      svg {
        width: 58px;
        height: 58px;
        border-radius: 12px;
        min-width: 58px;
      }
    }

    .card-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      font-weight: 600;
      @include bp(xlg-max) {
        max-width: 100%;
        flex: 0 0 100%;
        margin-top: 10px;
      }
      h2 {
        font-size: 24px;
        color: $primary;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 250px;
        @include bp(xlg-max) {
          max-width: 260px;
          font-size: 18px;
        }
      }
    }

    .listing-item {
      margin-top: 7px;
      li {
        font-size: 12px;
        display: flex;
        margin-right: 19px;
        align-items: center;
        &:last-child {
          margin-right: 0;
        }
      }

      img {
        height: 12px;
        margin-right: 6px;
      }
    }
  }

  .bottom-menu-card {
    margin: 6px 25px 0;
    padding-bottom: 10px;
    @include bp(xlg-max) {
      margin: 6px 15px 0;
      padding-bottom: 10px;
    }
  }
}