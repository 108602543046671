@import "../../../assets/scss/colors.scss";

.selected-package-header {
  border-left: 2px solid $secondary !important;
  border-right: 2px solid $secondary !important;
  border-top: 2px solid $secondary !important;
}

.selected-package-data {
  border-left: 2px solid $secondary !important;
  border-right: 2px solid $secondary !important;
}

.selected-package-footer {
  border-left: 2px solid $secondary !important;
  border-right: 2px solid $secondary !important;
  border-bottom: 2px solid $secondary !important;
}

.card-expiry {
  .MuiIconButton-root {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 100%;
    margin: 0;
    border-radius: 0;

    svg {
      display: none;
    }

    &:after {
      position: absolute;
      content: '';
      right: 6px;
      top: 8px;
      background-image: url(../../../assets/Icons/calendar.svg);
      height: 16px;
      width: 16px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 16px;
      transition: all 0.5s;
    }

    .MuiTouchRipple-root {
      display: none;
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}