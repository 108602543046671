@import "../../../assets/scss/colors.scss";
@import "../../../assets/scss/break-points.scss";

.profile-block {
  .profile-image-block {
    display: flex;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    width: 5rem;
    height: 5rem;
    border-radius: 9999px;
    cursor: pointer;
    background: $white_gray;

    @media (min-width: 640px) {
      width: 10rem;
      height: 10rem;
    }
  }

  .sub-heading {
    font-size: 0.75rem;
    line-height: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: .75rem;
    text-align: center;
    text-transform: uppercase;
    opacity: 0.4;
    color: $primary;
  }

  .profile-details {
    margin-top: 0.5rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    text-align: center;
    color: $primary;
  }
}