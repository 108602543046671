@import '../../../../assets/scss/colors.scss';

.contact-card-details-container {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 46px;
}

.left-side-details {
    width: 160px;
    height: 100%;

    img {
        width: 100%;
        height: 160px;
        border-radius: 50%;
    }
}

.right-side-details {
    width: calc(100% - 160px);
    height: 100%;
    padding-left: 43px;
}

.contact-name {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
    font-style: normal;
    font-family: 'Poppins';
    color: $primary;
    margin-bottom: 24px;
}

.contact-heading {
    font-size: 10px;
    font-weight: 600;
    font-style: normal;
    line-height: 12px;
    font-family: 'Poppins';
    color: $primary;
    opacity: 0.4;
    text-transform: uppercase;
}

.contact-value {
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    line-height: 22px;
    font-family: 'Poppins';
    color: $primary;
}